import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { LANGS_IRI } from '_lib/langs'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/formsetItem'
import formsetSchema from '_schema/formset'
import routes from './routes'

export const FormitemCollection = ({ ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(
    () => `${formsetSchema.endpoint}/${match.params.pid}`,
    [match.params.pid]
  )

  const columns = useMemo(
    () => [
      {
        header: translate('Name'),
        accessor: `translations.${
          LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
        }.titleRaw`,
        filterName: 'translations.title',
        filterable: true,
        width: '73%',
      },
      {
        ...operations(
          `translations.${
            LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
          }.titleRaw`,
          true,
          true,
          true,
          true,
          false
        ),
        width: '15%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj pole')}
        buttonPath={routes().formItemNew.path.replace(':pid', match.params.pid)}
      >
        {translate('Pola formularza')}
        &nbsp;
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource_edit.definition}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource_edit}
        defaultFilters={{
          'iri[parent]': parentIri,
        }}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().formItemEdit.path.replace(':pid', match.params.pid)}
        autoWidth={false}
        storeCollectionId={`${schema.endpoint}${match.params.pid}`}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ord]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
