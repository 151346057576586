import moment from 'moment'
import React from 'react'
import { Calendar } from 'react-multi-date-picker'

const gregorian_pl = {
  name: 'gregorian_pl',
  months: [
    ['styczeń', 'sty'],
    ['luty', 'lut'],
    ['marzec', 'mar'],
    ['kwiecień', 'kwi'],
    ['maj', 'maj'],
    ['czerwiec', 'cze'],
    ['lipiec', 'lip'],
    ['sierpień', 'sie'],
    ['wrzesień', 'wrz'],
    ['październik', 'paź'],
    ['listopad', 'lis'],
    ['grudzień', 'gru'],
  ],
  weekDays: [
    ['sobota', 'sob'],
    ['niedziela', 'ndz'],
    ['poniedziałek', 'pon'],
    ['wtorek', 'wt'],
    ['środa', 'śr'],
    ['czwartek', 'czw'],
    ['piątek', 'pt'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
}

export const QueryFormExcludedDatesType = ({
  name,
  label,
  value,
  disabled = false,
  setValue,
}) => {
  const handleChange = dates => {
    setValue(
      name,
      dates.map(date => date.format('YYYY-MM-DD'))
    )
  }

  return (
    <>
      <div style={{ marginBottom: 10, fontSize: '1.0em', color: 'gray' }}>
        {label}
      </div>
      <Calendar
        value={value}
        onChange={handleChange}
        multiple
        locale={gregorian_pl}
        disabled={disabled}
        weekStartDayIndex={1}
      />
    </>
  )
}
