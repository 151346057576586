import React from 'react'
import { CloudUploadOutlined } from '@material-ui/icons'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { NotFound } from 'pages'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { downloadFile } from '_helpers/downloadFile'
import { Title } from 'components/Title'
import moment from 'moment'
import schema from '_schema/formsetSave'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
    backgroundColor: 'transparent',
  },
  grid: {
    marginBottom: 70,
  },
  header: {
    marginBottom: 20,
  },
}))

export const FormSaveEdit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError
  )

  const classes = useStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <div className={classes.container}>
      <Title className={classes.header}>
        Data wysłania {moment(resource.createdAt).format('DD.MM.YYYY HH:mm')}
      </Title>
      <div>Język złożenia formularza: {resource.lang}</div>
      <div
        className={classes.root}
        style={{
          width: '100%',
        }}
      >
        <div className={classes.field}>
          <br />
          <Title className={classes.header}>Formularz:</Title>
          {resource.dataForm.map((item, index) => (
            <div style={{ padding: '5px' }} key={index}>
              <span style={{ fontWeight: 800 }}>
                {item.title.replace(/<[^>]+>/g, '')}
              </span>
              <span>
                {Array.isArray(item.value) ? (
                  <div
                    style={{
                      marginLeft: '20px',
                      listStyleType: 'circle',
                    }}
                  >
                    {item.value.map(option => (
                      <div key={option.uuid}>
                        -{' '}
                        {option?.title ? (
                          option?.type === 'consent' ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: option.title,
                              }}
                            />
                          ) : (
                            option.title
                          )
                        ) : (
                          '--'
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    <div style={{ marginLeft: 20 }}>
                      {item.value ?? '--'}
                      {item.file_uuid && (
                        <Button
                          startIcon={<CloudUploadOutlined />}
                          color="primary"
                          size="small"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            downloadFile(
                              schema.endpoint_file_download.replace(
                                ':uuid',
                                item.file_uuid
                              ),
                              item.value
                            )
                          }}
                        >
                          pobierz
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
