import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import mediaSchema from '_schema/media'
import { QueryFormExcludedDatesType } from 'pages/ConfigQueryForm/form/QueryFormExcludedDatesType'

const schema = {
  endpoint: '/api/config',
  access: 'config',
  resource: {
    definition: '#Config',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          contactAddress: { type: FIELD_TYPES.TEXTAREA },
          shopLink: {},
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
        },
      },
      email: {
        description: 'Email do obsługi formularzy',
      },
      isMuseumOpen: {},
      mondayTimeFrom: { type: FIELD_TYPES.TIME },
      mondayTimeTo: { type: FIELD_TYPES.TIME },
      tuesdayTimeFrom: { type: FIELD_TYPES.TIME },
      tuesdayTimeTo: { type: FIELD_TYPES.TIME },
      wednesdayTimeFrom: { type: FIELD_TYPES.TIME },
      wednesdayTimeTo: { type: FIELD_TYPES.TIME },
      thursdayTimeFrom: { type: FIELD_TYPES.TIME },
      thursdayTimeTo: { type: FIELD_TYPES.TIME },
      fridayTimeFrom: { type: FIELD_TYPES.TIME },
      fridayTimeTo: { type: FIELD_TYPES.TIME },
      saturdayTimeFrom: { type: FIELD_TYPES.TIME },
      saturdayTimeTo: { type: FIELD_TYPES.TIME },
      sundayTimeFrom: { type: FIELD_TYPES.TIME },
      sundayTimeTo: { type: FIELD_TYPES.TIME },
      isCinemaOpen: {},
      cinemaMondayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaMondayTimeTo: { type: FIELD_TYPES.TIME },
      cinemaTuesdayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaTuesdayTimeTo: { type: FIELD_TYPES.TIME },
      cinemaWednesdayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaWednesdayTimeTo: { type: FIELD_TYPES.TIME },
      cinemaThursdayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaThursdayTimeTo: { type: FIELD_TYPES.TIME },
      cinemaFridayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaFridayTimeTo: { type: FIELD_TYPES.TIME },
      cinemaSaturdayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaSaturdayTimeTo: { type: FIELD_TYPES.TIME },
      cinemaSundayTimeFrom: { type: FIELD_TYPES.TIME },
      cinemaSundayTimeTo: { type: FIELD_TYPES.TIME },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'Zdjęcie na facebooka',
        endpoint: mediaSchema.endpoint,
        validate: ['maxSize'],
      },
    },
  },
  forms: {
    cookies: {
      definition: '#Config',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            cookiesBoxTitle: {
              label: 'Tytuł boxa',
            },
            cookiesBoxMsg: {
              type: FIELD_TYPES.TEXTAREA,
              label: 'Główny tekst w boxie',
            },
          },
        },
      },
    },
    query_form: {
      definition: '#Config',
      properties: {
        queryFormEmail: {
          description: 'Formularz kwerend: główne emaile (oddziel przecinkiem)',
        },
        queryFormCollectionsEmail: {
          description:
            'Formularz kwerend: emaile do obsługi kolekcji (oddziel przecinkiem)',
        },
        queryFormArchivesEmail: {
          description:
            'Formularz kwerend: emaile do obsługi archiw (oddziel przecinkiem)',
        },
        queryFormLibraryEmail: {
          description:
            'Formularz kwerend: emaile do obsługi księgozbiorów (oddziel przecinkiem)',
        },
        isQueryFormMondayAvailable: {},
        queryFormMondayHours: {},
        isQueryFormTuesdayAvailable: {},
        queryFormTuesdayHours: {},
        isQueryFormWednesdayAvailable: {},
        queryFormWednesdayHours: {},
        isQueryFormThursdayAvailable: {},
        queryFormThursdayHours: {},
        isQueryFormFridayAvailable: {},
        queryFormFridayHours: {},
        isQueryFormSaturdayAvailable: {},
        queryFormSaturdayHours: {},
        isQueryFormSundayAvailable: {},
        queryFormSundayHours: {},
        queryFormExcludedDates: { type: QueryFormExcludedDatesType },
      },
    },
    share_form: {
      definition: '#Config',
      properties: {
        shareFormEmail: {
          description:
            'Formularz udostępnień: główne emaile (oddziel przecinkiem)',
        },
        shareFormCollectionEmail: {
          description:
            'Formularz udostępnień: emaile do obsługi kolekcji (oddziel przecinkiem)',
        },
        shareFormFilmLibraryEmail: {
          description:
            'Formularz udostępnień: emaile do obsługi filmoteki (oddziel przecinkiem)',
        },
        shareFormArchiveEmail: {
          description:
            'Formularz udostępnień: emaile do obsługi archiwów (oddziel przecinkiem)',
        },
        shareFormPafdEmail: {
          description:
            'Formularz udostępnień: emaile do obsługi PAFD (oddziel przecinkiem)',
        },
      },
    },
  },
}

export default schema
