import { LANGS_IRI } from '_lib/langs'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import formsetItemTypeSchema from '_schema/_enums/formsetItemType'

const schema = {
  endpoint: '/api/formset_items',
  resource_new: {
    definition: '#FormsetItem',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {
            label: 'Tytuł',
          },
        },
      },
      type: {
        description: 'Typ pola',
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: formsetItemTypeSchema.endpoint,
        validate: ['required'],
      },
    },
  },
  resource_edit: {
    definition: '#FormsetItem',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {
            label: 'Tytuł',
            hint: 'nazwa widoczna tylko w PA',
          },
          shortLead: {
            type: FIELD_TYPES.TEXTAREA,
          },
          placeholder: {},
          errorMessage: {},
        },
      },
      type: {
        description: 'Typ pola',
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: formsetItemTypeSchema.endpoint,
        disabled: true,
      },
      required: {},
      fileSize: {
        type: FIELD_TYPES.NUMBER,
        hint: 'podaj maksymalny rozmiar w MB',
        validate: ['required'],
      },
      fileExtension: {
        hint: 'podaj po przecinku, np jpg,gif,png',
        validate: ['required'],
      },
      textareaSize: {},
      maxCount: {
        type: FIELD_TYPES.INTEGER,
        minValue: 0,
        defaultValue: 0,
        hint: 'wpisz 0 lub puste pole aby wyłaczyć',
      },
      stat: {},
      options: {
        type: FIELD_TYPES.EMBEDDED_COLLECTION,
        endpoint: '/api/formset_item_options',
        definitionRef: '#FormsetItemOption',
        description: 'Opcje pola',
        order: 'desc',
        titleAccessor: `translations.${
          LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
        }.title`,
        properties: {
          translations: {
            type: FIELD_TYPES.TRANSLATION,
            properties: {
              title: {
                type: FIELD_TYPES.STRING,
              },
              shortLead: {
                type: FIELD_TYPES.TINYMCE,
              },
            },
          },
          email: {
            type: FIELD_TYPES.STRING,
          },
          isDefault: {},
          required: {},
          stat: {},
        },
      },
    },
  },
}

export default schema
