import React from 'react'
import { Form } from 'pages'
import { SidebarNotStatable } from 'components/resource/sidebar'
import { modifyPropertiesByPage } from './_helpers/modifyPropertiesByPage'
import schema from '_schema/formsetItem'
import routes from './routes'

export const FormitemEdit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource_edit.definition]}
      customResourceSchema={schema.resource_edit}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().formItemsIndex.path.replace(
        ':pid',
        match.params.pid
      )}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      SidebarComponent={SidebarNotStatable}
      width="100%"
      modifyProperties={(properties, resource, schema) => {
        return modifyPropertiesByPage(properties, resource, schema)
      }}
    />
  )
}
