import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { DatetimeCell } from 'components/table/cells/DatetimeCell'
import schema from '_schema/submittedQueryForm'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: 'Numer',
        accessor: 'code',
        width: '15%',
      },
      {
        header: 'Imię i nazwisko',
        accessor: 'applicantFullName',
        width: '50%',
      },
      {
        header: 'Data złożenia',
        accessor: 'createdAt',
        Cell: DatetimeCell,
        width: '15%',
      },
      {
        ...operations('title', false, true, false),
        width: '12%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader buttonTitle="" buttonPath="" hideButton={true}>
        Formularz kwerend - złożone formularze
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        parameters={[
          {
            name: 'order[createdAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        orderable={false}
        isRowLinkable={true}
        editPath={routes().view.path}
        autoWidth={false}
      />
    </Paper>
  )
}
