import adminSchema from '_schema/admin'
import loginEntry from '_schema/loginEntry'
import registryEntry from '_schema/registryEntry'
import page from '_schema/page'
import translatorEntry from '_schema/translatorEntry'
import artist from '_schema/artist'
import collection from '_schema/collection'
import event from '_schema/event'
import exhibition from '_schema/exhibition'
import exhibit from '_schema/exhibit'
import news from '_schema/news'
import footerMenuItem from '_schema/footerMenuItem'
import homeSlide from '_schema/homeSlide'
import branch from '_schema/branch'
import resource from '_schema/resource'
import magazine from '_schema/magazine'
import newsletterCategory from '_schema/newsletterCategory'
import managerMedia from '_schema/managerMedia'
import publishingHousePublication from '_schema/publishingHousePublication'
import archiveArticle from '_schema/archiveArticle'
import job from '_schema/job'
import importEntry from '_schema/importEntry'
import formset from '_schema/formset'
import clonerQueueItem from '_schema/clonerQueueItem'
import pressArticle from '_schema/pressArticle'
import shareFormCinemaCategory from '_schema/shareFormCinemaCategory'
import shareFormExhibitionCategory from '_schema/shareFormExhibitionCategory'
import shareFormPromotionCategory from '_schema/shareFormPromotionCategory'
import shareFormPublisherCategory from '_schema/shareFormPublisherCategory'
import shareFormScientificCategory from '_schema/shareFormScientificCategory'
import shareFormTheaterCategory from '_schema/shareFormTheaterCategory'
import shareFormPafdChoice from '_schema/shareFormPafdChoice'
import shareFormArchiveChoice from '_schema/shareFormArchiveChoice'
import queryFormCollectionChoice from '_schema/queryFormCollectionChoice'
import queryFormArchiveChoice from '_schema/queryFormArchiveChoice'
import queryFormLibraryChoice from '_schema/queryFormLibraryChoice'
import submittedShareFrom from '_schema/submittedShareForm'
import submittedQueryFrom from '_schema/submittedQueryForm'
import cookieItem from '_schema/cookieItem'

const resources = () => ({
  [page.access]: 'Pages',
  [artist.access]: 'Artyści',
  [exhibit.access]: 'Kolekcja i Archiwa',
  [resource.access]: 'Media',
  [collection.access]: 'Kolekcje',
  [exhibition.access]: 'Wystawy',
  [event.access]: 'Wydarzenia',
  [news.access]: 'Aktualności',
  [magazine.access]: 'Magazyn',
  [pressArticle.access]: 'Prasa',
  [job.access]: 'Oferty pracy',
  [archiveArticle.access]: 'Archiwa MSN',
  [publishingHousePublication.access]: 'Wydawnictwo',
  [homeSlide.access]: 'Slider na SG',
  [branch.access]: 'Oddziały muzeum',
  [newsletterCategory.access]: 'Newsletter grupy',
  [formset.access]: 'Formularze',
  [footerMenuItem.access]: 'Stopka',
  [adminSchema.access]: 'Administrators',
  [loginEntry.access]: 'Login entries',
  [registryEntry.access]: 'Registry entries',
  [translatorEntry.access]: 'Translator entries',
  [importEntry.access]: 'Importer',
  [clonerQueueItem.access]: 'Kolejka klonowania',
  [managerMedia.access]: 'File manager',
  [cookieItem.access]: 'Cookies',
  [submittedShareFrom.access]: 'Formularz udostępnień - złożone formularze',
  [shareFormCinemaCategory.access]: 'Formularz udostępnień - kategorie TV/Film',
  [shareFormExhibitionCategory.access]:
    'Formularz udostępnień - kategorie wystawy',
  [shareFormPromotionCategory.access]:
    'Formularz udostępnień - kategorie promocji',
  [shareFormPublisherCategory.access]:
    'Formularz udostępnień - kategorie wydawnictwa',
  [shareFormScientificCategory.access]:
    'Formularz udostępnień - kategorie naukowe',
  [shareFormTheaterCategory.access]: 'Formularz udostępnień - kategorie teatru',
  [shareFormPafdChoice.access]: 'Formularz udostępnień - opcje PAFD',
  [shareFormArchiveChoice.access]: 'Formularz udostępnień - opcje Archiwa',
  [submittedQueryFrom.access]: 'Formularz kwerend - złożone formularze',
  [queryFormCollectionChoice.access]: 'Formularz kwerend - opcje Kolekcje',
  [queryFormArchiveChoice.access]: 'Formularz kwerend - opcje Archiwa',
  [queryFormLibraryChoice.access]: 'Formularz kwerend - opcje Księgozbiory',
})

export default resources
