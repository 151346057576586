import React from 'react'
import { Route } from 'react-router-dom'
import { Settings } from '@material-ui/icons'
import { SchemableComponent } from 'components/SchemableComponent'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/config'

const PATH = '/config'

const routes = () =>
  addRoutesConfig(
    {
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: PATH,
        exact: true,
        icon: Settings,
      },
    },
    {
      title: 'Konfiguracja',
      resourceAccess: schema.access,
      indexPath: PATH,
    }
  )

export default routes
