import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CloudDownloadOutlined } from '@material-ui/icons'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { useDispatch } from 'react-redux'
import { downloadFile } from '_helpers/downloadFile'
import { commonConstants } from '_constants'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { NotFound } from 'pages/NotFound'
import { LANGS_IRI } from '_lib/langs'
import schema from '_schema/submittedQueryForm'
import moment from 'moment'

const useStyles = makeStyles({
  root: {
    fontSize: 16,
  },
  bold: {
    fontSize: 17,
    fontWeight: 'bold',
  },
  row: {
    marginTop: 10,
    marginBottom: 10,
  },
})

export const View = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [dispatch]
  )

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    false,
    setState.isNotEditable,
    dispatchOnFetch
  )

  const classes = useStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <div className={classes.root}>
        <div className={classes.row}>
          <span className={classes.bold}>Numer zgłoszenia:</span>{' '}
          {resource.code}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Data zgłoszenia:</span>{' '}
          {moment(resource.createdAt).format('DD.MM.YYYY')}
        </div>
        <div className={classes.row} style={{ marginTop: 30 }}>
          <span className={classes.bold}>Imię:</span> {resource.applicantName}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Nazwisko:</span>{' '}
          {resource.applicantSurname}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Telefon kontaktowy:</span>{' '}
          {resource.applicantPhone}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Email:</span> {resource.applicantEmail}
        </div>
        <div className={classes.row} style={{ marginTop: 30 }}>
          <span className={classes.bold}>Private/Professional:</span>{' '}
          {resource.querer.title}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Afiliacja:</span>{' '}
          {resource.quererAffiliation}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Stanowisko:</span>{' '}
          {resource.quererPosition?.title}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Ulica i numer:</span>{' '}
          {resource.quererAddress}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Miasto:</span> {resource.quererCountry}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Kod pocztowy:</span>{' '}
          {resource.quererPostal}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Państwo:</span>{' '}
          {resource.quererCountry}
        </div>
        <div className={classes.row} style={{ marginTop: 30 }}>
          <span className={classes.bold}>Cel badań:</span>{' '}
          {resource.researchObjective?.title}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Temat badań:</span>
        </div>
        <div className={classes.row}>
          {resource.researchDescription?.split('\n').map((row, index) =>
            row !== '' ? (
              <div key={index}>
                {row.replaceAll('&#34;', '"')}
                <br />
              </div>
            ) : null
          )}
        </div>
        <div className={classes.row} style={{ marginTop: 30 }}>
          <span className={classes.bold}>Departament:</span>{' '}
          {resource.subject?.title}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Kolekcje:</span>{' '}
          {resource.collection?.translations[LANGS_IRI.PL]?.title}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Archiwa i Księgozbiory:</span>{' '}
          {resource.archive?.translations[LANGS_IRI.PL]?.title ||
            resource.library?.translations[LANGS_IRI.PL]?.title}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Szczegóły:</span>
        </div>
        <div className={classes.row}>
          {resource.queryDescription?.split('\n').map((row, index) =>
            row !== '' ? (
              <div key={index}>
                {row.replaceAll('&#34;', '"')}
                <br />
              </div>
            ) : null
          )}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Załącznik:</span>
          {resource.attachmentOriginalName && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() =>
                downloadFile(
                  schema.endpoint_attachment.replace(':uuid', resource.uuid),
                  resource.attachmentOriginalName
                )
              }
            >
              {resource.attachmentOriginalName}
              <CloudDownloadOutlined style={{ marginLeft: 10 }} />
            </div>
          )}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Preferowana data:</span>{' '}
          {moment(resource.primaryDate).format('DD.MM.YYYY')}{' '}
          {resource.primaryTime}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Dodatkowy termin:</span>{' '}
          {resource.secondaryDate
            ? moment(resource.secondaryDate).format('DD.MM.YYYY')
            : null}{' '}
          {resource.secondaryTime}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Dodatkowe pytania:</span>
        </div>
        <div className={classes.row}>
          {resource.additionalQuestions?.split('\n').map((row, index) =>
            row !== '' ? (
              <div key={index}>
                {row.replaceAll('&#34;', '"')}
                <br />
              </div>
            ) : null
          )}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Wizyta:</span>{' '}
          {resource.isFirstQuery
            ? 'Będzie to moja pierwsza kwerenda w MSN'
            : 'Byłam/byłem już wcześniej na kwerendzie w MSN'}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Zgody:</span>
        </div>
        <div className={classes.row}>
          {resource.agreements.split('\n').map((row, index) =>
            row !== '' ? (
              <div key={index}>
                {row.replaceAll('&#34;', '"')}
                <br />
              </div>
            ) : null
          )}
        </div>
      </div>
    </Paper>
  )
}
