const schema = {
  endpoint: '/api/submitted_share_forms',
  endpoint_attachment: '/api/submitted_share_forms/:uuid/attachment',
  access: 'submittedShareForm',
  resource: {
    definition: '#SubmittedShareForm',
    properties: {
      applicantName: {},
      applicantSurname: {},
      applicantPhone: {},
      applicantEmail: {},
    },
  },
}

export default schema
