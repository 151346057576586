import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/cookie_ips',
  access: 'cookieItem',
  resource: {
    definition: '#CookieIp',
    properties: {
      ip: {
        label: 'IP',
      },
      userAgent: {
        label: 'Przeglądarka',
        type: FIELD_TYPES.TEXTAREA,
      },
      cookies: {
        label: 'Zapisane cookies',
        type: FIELD_TYPES.TEXTAREA,
      },
      referer: {
        label: 'Skąd akcja',
        type: FIELD_TYPES.STRING,
      },
    },
  },
}

export default schema
