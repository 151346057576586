const schema = {
  endpoint: '/api/formset_saves',
  endpoint_file_download: '/api/formset_save_files/:uuid/download',
  resource: {
    definition: '#FormsetSave',
    properties: {},
  },
}

export default schema
