export const modifyPropertiesByPage = (properties, resource) => {
  let modifiedProperties = JSON.parse(JSON.stringify(properties))

  let type = resource.type.value

  switch (type) {
    case 'textarea':
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.maxCount
      delete modifiedProperties.items
      delete modifiedProperties.options
      return modifiedProperties

    case 'select':
    case 'multiple':
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.textareaSize
      delete modifiedProperties.maxCount
      delete modifiedProperties.options.properties.translations.properties
        .shortLead
      delete modifiedProperties.options.properties.required
      return modifiedProperties

    case 'checkbox':
    case 'radio':
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.textareaSize
      delete modifiedProperties.maxCount
      delete modifiedProperties.translations.properties.placeholder
      delete modifiedProperties.options.properties.translations.properties
        .shortLead
      delete modifiedProperties.options.properties.required
      return modifiedProperties

    case 'file':
      delete modifiedProperties.textareaSize
      delete modifiedProperties.maxCount
      delete modifiedProperties.options
      return modifiedProperties

    case 'number':
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.textareaSize
      delete modifiedProperties.options
      return modifiedProperties

    case 'header':
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.textareaSize
      delete modifiedProperties.options
      delete modifiedProperties.maxCount
      delete modifiedProperties.required
      delete modifiedProperties.translations.properties.shortLead
      delete modifiedProperties.translations.properties.placeholder
      delete modifiedProperties.translations.properties.errorMessage
      return modifiedProperties

    case 'description':
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.textareaSize
      delete modifiedProperties.options
      delete modifiedProperties.maxCount
      delete modifiedProperties.required
      delete modifiedProperties.translations.properties.placeholder
      delete modifiedProperties.translations.properties.errorMessage
      return modifiedProperties

    case 'consent':
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.textareaSize
      delete modifiedProperties.maxCount
      delete modifiedProperties.options.properties.email
      delete modifiedProperties.translations.properties.placeholder

      modifiedProperties.options.properties.translations.properties.title.hint =
        'nazwa widoczna tylko w panelu'
      modifiedProperties.options.properties.translations.properties.shortLead.label =
        'Treść zgody'

      return modifiedProperties

    default:
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.textareaSize
      delete modifiedProperties.options
      delete modifiedProperties.maxCount
      return modifiedProperties
  }
}
