import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { TermCell } from './table/cells/TermCell'
import { CustomButton } from 'components/CustomButton'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { commonConstants } from '_constants'
import schema from '_schema/event'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('Title'),
        accessor: 'title',
        filterable: true,
        sortable: true,
        width: '80%',
      },
      {
        header: translate('Harmonogram'),
        accessor: 'termsCount',
        width: '10%',
        Cell: TermCell,
      },
      {
        ...operations('title', true, true, true, true, true),
        width: '12%',
      },
    ],
    []
  )

  const dispatch = useDispatch()

  const handleTemplate = () => {
    fetchDataHandleAuthError(
      schema.endpoint_template,
      'POST',
      { body: JSON.stringify({}) },
      () => {
        notification('success', 'Wydarzenie dodane', 'T_FORM_SUCCESS')
        dispatch({ type: commonConstants.INIT_RELOAD })
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <Paper>
      <CollectionHeader
        buttonTitle="Dodaj nowe wydarzenie"
        buttonPath={routes().new.path}
        additionalButtons={
          <CustomButton color="secondary" onClick={handleTemplate}>
            Utwórz z szablonu
          </CustomButton>
        }
      >
        Wydarzenia
      </CollectionHeader>

      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        customFilters={[
          {
            name: 'stat',
            title: 'Status',
          },
        ]}
        defaultSorters={{ ord: 'desc' }}
        parameters={[
          {
            name: 'title',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'stat',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'order[title]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ord]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        collectionTitle="Wydarzenia"
      />
    </Paper>
  )
}
