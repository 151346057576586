const schema = {
  endpoint: '/api/submitted_query_forms',
  endpoint_attachment: '/api/submitted_query_forms/:uuid/attachment',
  access: 'SubmittedQueryForm',
  resource: {
    definition: '#SubmittedQueryForm',
    properties: {
      applicantName: {},
      applicantSurname: {},
      applicantPhone: {},
      applicantEmail: {},
    },
  },
}

export default schema
