import { LANGS_IRI } from '_lib/langs'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/cookie_items',
  resource: {
    definition: '#CookieItem',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: { label: 'Nazwa grupy' },
          shortLead: { type: FIELD_TYPES.TEXTAREA },
        },
      },
      required: {},
      stat: {},
      script: { type: FIELD_TYPES.TEXTAREA },
      options: {
        type: FIELD_TYPES.EMBEDDED_COLLECTION,
        endpoint: '/api/cookie_item_options',
        definitionRef: '#CookieItemOption',
        description: 'Poszczególne cookies',
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        properties: {
          translations: {
            type: FIELD_TYPES.TRANSLATION,
            properties: {
              title: {},
              provider: {},
              shortLead: {
                type: FIELD_TYPES.TEXTAREA,
              },
              date: {},
              kind: {},
            },
          },

          stat: {},
        },
      },
    },
  },
  forms: {
    add: {
      definition: '#CookieItem',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: { label: 'Nazwa grupy' },
            shortLead: { type: FIELD_TYPES.TEXTAREA },
          },
        },
      },
    },
  },
}

export default schema
