import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/query_form_library_choices',
  access: 'queryFormLibraryChoice',
  resource: {
    definition: '#QueryFormLibraryChoice',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
        },
      },
    },
  },
}

export default schema
